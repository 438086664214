import { StringEmpty } from "@/export/CommonConst";

type FileItemType = {
  type: string;
  name: string;
  data: ArrayBuffer;
};
const DefaultFileItem: FileItemType = {
  type: StringEmpty,
  name: StringEmpty,
  data: new ArrayBuffer(0),
};
export { FileItemType, DefaultFileItem };
