import { StringEmpty } from "./CommonConst";

const isNullOrEmpty = (value: string): boolean => {
  return value == null || value == StringEmpty;
};

const isNullOrEmptyOrWhiteSpace = (value: string): boolean => {
  if (isNullOrEmpty(value)) {
    return true;
  }
  return value.trim() == StringEmpty;
};

/**
 * string型の値を取得する。
 * v-text-fieldのクリアボタンクリックでnullになる時の対応
 *
 * @param value 元の値
 * @returns string型の値
 */
const getStringValue = (value: string) => {
  if (value == null || value == undefined) {
    return StringEmpty;
  } else {
    return value;
  }
};

/**
 * number型の値を取得する。
 * v-text-fieldのクリアボタンクリックでnullになったり、
 * string型だったりする時の対応
 *
 * @param value 元の値
 * @returns number型の値
 */
const getNumberValue = (value: number) => {
  if (value == null || value == undefined) {
    return 0;
  } else if (typeof value == "string") {
    if (value == StringEmpty) {
      return 0;
    } else {
      return parseInt(value);
    }
  } else {
    return value;
  }
};

/**
 *
 * @param value
 * @param digit
 * @param padChar
 * @returns
 */
const padStart = (value: number, digit: number, padChar: string): string => {
  return String(value).padStart(digit, padChar);
};

export default {
  isNullOrEmpty,
  isNullOrEmptyOrWhiteSpace,
  getStringValue,
  getNumberValue,
  padStart,
};

export {
  isNullOrEmpty,
  isNullOrEmptyOrWhiteSpace,
  getStringValue,
  getNumberValue,
  padStart,
};
