type RunnerMasterItemType = {
  runnerId: number;
  numberCard: string;
  tagNumber1: string;
  tagNumber2: string;
  categoryItemCode: number;
  categoryItemName: string;
  runnerName: string;
  runnerNameKana: string;
  gender: string;
  genderValue: number;
  age: number;
  prefecture: string;
  city: string;
  team: string;
  isDisqualified: number;
  isDisqualifiedText: string;
  isDisplay: number;
  isDisplayText: string;
};

/**
 * 性別の値
 */
const GenderTypeValue = {
  Man: 0,
  Woman: 1,
};
type GenderTypeValue = (typeof GenderTypeValue)[keyof typeof GenderTypeValue];

/**
 * 性別のテキスト
 */
const GenderTypeText = {
  Man: "男",
  Woman: "女",
};
type GenderTypeText = (typeof GenderTypeText)[keyof typeof GenderTypeText];

/**
 * 性別の項目
 */
const GenderTypeItem = {
  Man: { text: GenderTypeText.Man, value: GenderTypeValue.Man },
  Women: { text: GenderTypeText.Woman, value: GenderTypeValue.Woman },
};
type GenderTypeItem = (typeof GenderTypeItem)[keyof typeof GenderTypeItem];

/**
 * 失格フラグの値
 */
const IsDisqualifiedTypeValue = {
  Off: 0,
  On: 1,
};
type IsDisqualifiedTypeValue =
  (typeof IsDisqualifiedTypeValue)[keyof typeof IsDisqualifiedTypeValue];

/**
 * 失格フラグのテキスト
 */
const IsDisqualifiedTypeText = {
  Off: "－",
  On: "失格",
};
type IsDisqualifiedTypeText =
  (typeof IsDisqualifiedTypeText)[keyof typeof IsDisqualifiedTypeText];

/**
 * 失格フラグの項目
 */
const IsDisqualifiedTypeItem = {
  Off: {
    text: IsDisqualifiedTypeText.Off,
    value: IsDisqualifiedTypeValue.Off,
  },
  On: {
    text: IsDisqualifiedTypeText.On,
    value: IsDisqualifiedTypeValue.On,
  },
};
type IsDisqualifiedTypeItem =
  (typeof IsDisqualifiedTypeItem)[keyof typeof IsDisqualifiedTypeItem];

/**
 * 表示フラグの値
 */
const IsDisplayTypeValue = {
  Off: 0,
  On: 1,
};
type IsDisplayTypeValue =
  (typeof IsDisplayTypeValue)[keyof typeof IsDisplayTypeValue];

/**
 * 表示フラグのテキスト
 */
const IsDisplayTypeText = {
  Off: "非表示",
  On: "表示",
};
type IsDisplayTypeText =
  (typeof IsDisplayTypeText)[keyof typeof IsDisplayTypeText];

/**
 * 表示フラグの項目
 */
const IsDisplayTypeItem = {
  Off: {
    text: IsDisplayTypeText.Off,
    value: IsDisplayTypeValue.Off,
  },
  On: {
    text: IsDisplayTypeText.On,
    value: IsDisplayTypeValue.On,
  },
};
type IsDisplayTypeItem =
  (typeof IsDisplayTypeItem)[keyof typeof IsDisplayTypeItem];

export default {
  GenderTypeValue,
  GenderTypeText,
  GenderTypeItem,
  IsDisqualifiedTypeValue,
  IsDisqualifiedTypeText,
  IsDisqualifiedTypeItem,
  IsDisplayTypeValue,
  IsDisplayTypeText,
  IsDisplayTypeItem,
};

export {
  RunnerMasterItemType,
  GenderTypeValue,
  GenderTypeText,
  GenderTypeItem,
  IsDisqualifiedTypeValue,
  IsDisqualifiedTypeText,
  IsDisqualifiedTypeItem,
  IsDisplayTypeValue,
  IsDisplayTypeText,
  IsDisplayTypeItem,
};
