const NewLine = "\n";
const StringEmpty = "";
const WhiteSpace = " ";
const NoDataText = "データがありません。";
const ItemsPerPageText = "行/ページ:";
const DefaultItmesPerPage = 100;
const ItemsPerPageOptions = [DefaultItmesPerPage, 500, 1000];
const InvalidId = -1;
const DateDelimiter = "/";
const TimeDelimiter = ":";
const DateItemNum = 3;
const TimeItemNum = 3;
const Millisecond = 1000;

const DateItemIndex = {
  Year: 0,
  Month: 1,
  Date: 2,
};
type DateItemIndex = (typeof DateItemIndex)[keyof typeof DateItemIndex];

const TimeItemIndex = {
  Hour: 0,
  Minute: 1,
  Second: 2,
};
type TimeItemIndex = (typeof TimeItemIndex)[keyof typeof TimeItemIndex];

export default {
  NewLine,
  StringEmpty,
  WhiteSpace,
  NoDataText,
  ItemsPerPageText,
  DefaultItmesPerPage,
  ItemsPerPageOptions,
  InvalidId,
  DateDelimiter,
  TimeDelimiter,
  DateItemNum,
  TimeItemNum,
  DateItemIndex,
  TimeItemIndex,
  Millisecond,
};
export {
  NewLine,
  StringEmpty,
  WhiteSpace,
  NoDataText,
  ItemsPerPageText,
  DefaultItmesPerPage,
  ItemsPerPageOptions,
  InvalidId,
  DateDelimiter,
  TimeDelimiter,
  DateItemNum,
  TimeItemNum,
  DateItemIndex,
  TimeItemIndex,
  Millisecond,
};
