// 参考
// Vue2 Composition API でのVue Router3系の使い方
// https://qiita.com/gw513405/items/3fe117e5bd913440c4c1
import ComponentInternalInstance, { getCurrentInstance } from "vue";
import VueRouter, { Route } from "vue-router";
import { Framework } from "vuetify";

const getInstance = (): ComponentInternalInstance => {
  const instance = getCurrentInstance();
  if (!instance) {
    throw new Error("Should be used in setup.");
  }
  return instance.proxy;
};

const useRouter = (): VueRouter => {
  const instance = getInstance();
  return instance.$router;
};

const useRoute = (): Route => {
  const instance = getInstance();
  return instance.$route;
};

const useVuetify = (): Framework => {
  const instance = getInstance();
  return instance.$vuetify;
};

export default { useRouter, useRoute, useVuetify };
export { useRouter, useRoute, useVuetify };
