import { computed } from "vue";
import axios, { AxiosResponse, AxiosError } from "axios";
import store, { updateIdToken } from "@/store/index";
import {
  CognitoUserPool,
  CognitoUser,
  CognitoRefreshToken,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { HttpStatus } from "./HttpStatusConst";

/**
 * IDトークン
 */
const idToken = computed(() => {
  return store.state.idToken;
});

/**
 * リフレッシュトークン
 */
const refreshToken = computed(() => {
  return store.state.refreshToken;
});

/**
 * ユーザID
 */
const userId = computed(() => {
  return store.state.userId;
});

/**
 * GET要求する。
 * @param url URL
 * @param isRetry リトライフラグ(true:リトライ/false:リトライではない)
 * @returns レスポンス
 */
const getAsync = async (
  url: string,
  signal?: AbortSignal,
  isRetry = false
): Promise<AxiosResponse> => {
  try {
    // console.log(`getAsync.url=${url}`);
    const config = getConfig();
    if (signal) {
      const instance = axios.create(config);
      return await instance.get(url, { signal: signal });
    } else {
      return await axios.get(url, config);
    }
  } catch (error) {
    if (!isRetry && isUnauthorized(error)) {
      // リトライではなく、認証エラーの場合
      // IDトークンを取得する。
      const result = await getIdToken();
      if (result) {
        // IDトークンが取得できた場合
        // リトライとして再実行する。
        return await getAsync(url, signal, true);
      }
    }
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};

const getBlobAsync = async (
  url: string,
  signal?: AbortSignal,
  isRetry = false
): Promise<AxiosResponse> => {
  try {
    // console.log(`getBlobAsync.url=${url}`);
    const config = getConfig();
    const instance = axios.create(config);
    if (signal) {
      return await instance({
        url: url,
        method: "get",
        responseType: "blob",
        signal: signal,
      });
    } else {
      return await instance({
        url: url,
        method: "get",
        responseType: "blob",
      });
    }
  } catch (error) {
    if (!isRetry && isUnauthorized(error)) {
      // リトライではなく、認証エラーの場合
      // IDトークンを取得する。
      const result = await getIdToken();
      if (result) {
        // IDトークンが取得できた場合
        // リトライとして再実行する。
        return await getBlobAsync(url, signal, true);
      }
    }
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};

/**
 * POST要求する。
 * @param url URL
 * @param data データ
 * @param isRetry リトライフラグ(true:リトライ/false:リトライではない)
 * @returns レスポンス
 */
const postAsync = async (
  url: string,
  data?: any,
  signal?: AbortSignal,
  isRetry = false
): Promise<AxiosResponse> => {
  try {
    // console.log(`postAsync.url=${url}`);
    const config = getConfig();
    if (signal) {
      const instance = axios.create(config);
      return await instance.post(url, data, { signal: signal });
    } else {
      return await axios.post(url, data, config);
    }
  } catch (error) {
    // console.log(error);
    if (!isRetry && isUnauthorized(error)) {
      // リトライではなく、認証エラーの場合
      // IDトークンを取得する。
      const result = await getIdToken();
      if (result) {
        // IDトークンが取得できた場合
        // リトライとして再実行する。
        return await postAsync(url, data, signal, true);
      }
    }
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};

/**
 * ファイルをPOST要求する。
 * @param url URL
 * @param data データ
 * @param isRetry リトライフラグ(true:リトライ/false:リトライではない)
 * @returns レスポンス
 */
const postFileAsync = async (
  url: string,
  data: any,
  signal?: AbortSignal,
  isRetry = false
): Promise<AxiosResponse> => {
  try {
    // console.log(`postFileAsync.url=${url}`);
    const config = getMultipartConfig();
    if (signal) {
      const instance = axios.create(config);
      return await instance.post(url, data, { signal: signal });
    } else {
      return await axios.post(url, data, config);
    }
  } catch (error) {
    // console.log(error);
    if (!isRetry && isUnauthorized(error)) {
      // リトライではなく、認証エラーの場合
      // IDトークンを取得する。
      const result = await getIdToken();
      if (result) {
        // IDトークンが取得できた場合
        // リトライとして再実行する。
        return await postFileAsync(url, data, signal, true);
      }
    }
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};

/**
 * PUT要求する。
 * @param url URL
 * @param data データ
 * @param isRetry リトライフラグ(true:リトライ/false:リトライではない)
 * @returns レスポンス
 */
const putAsync = async (
  url: string,
  data: any,
  signal?: AbortSignal,
  isRetry = false
): Promise<AxiosResponse> => {
  try {
    // console.log(`putAsync.url=${url}`);
    const config = getConfig();
    if (signal) {
      const instance = axios.create(config);
      return await instance.put(url, data, { signal: signal });
    } else {
      return await axios.put(url, data, config);
    }
  } catch (error) {
    if (!isRetry && isUnauthorized(error)) {
      // リトライではなく、認証エラーの場合
      // IDトークンを取得する。
      const result = await getIdToken();
      if (result) {
        // IDトークンが取得できた場合
        // リトライとして再実行する。
        return await putAsync(url, data, signal, true);
      }
    }
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};

/**
 * DELETE要求する。
 * @param url URL
 * @param isRetry リトライフラグ(true:リトライ/false:リトライではない)
 * @returns レスポンス
 */
const deleteAsync = async (
  url: string,
  signal?: AbortSignal,
  isRetry = false
): Promise<AxiosResponse> => {
  try {
    // console.log(`deleteAsync.url=${url}`);
    const config = getConfig();
    if (signal) {
      const instance = axios.create(config);
      return await instance.delete(url, { signal: signal });
    } else {
      return await axios.delete(url, config);
    }
  } catch (error) {
    if (!isRetry && isUnauthorized(error)) {
      // リトライではなく、認証エラーの場合
      // IDトークンを取得する。
      const result = await getIdToken();
      if (result) {
        // IDトークンが取得できた場合
        // リトライとして再実行する。
        return await deleteAsync(url, signal, true);
      }
    }
    const errorMessage = getErrorMessage(error);
    throw new Error(errorMessage);
  }
};

/**
 * アボートコントローラを生成する。
 * @param timeout タイムアウト(デフォルト30秒)
 * @returns
 */
const createAbortController = (timeout: number = 30 * 1000) => {
  const abortController = new AbortController();
  setTimeout(() => abortController.abort(), timeout || 0);
  return abortController;
};

/**
 * コンフィグを取得する。
 * @returns コンフィグオブジェクト
 */
const getConfig = (): object => {
  return {
    headers: {
      Authorization: `Bearer ${idToken.value}`,
    },
  };
};

/**
 * マルチパートコンフィグを取得する。
 * @returns マルチパートコンフィグオブジェクト
 */
const getMultipartConfig = (): object => {
  return {
    headers: {
      "Content-type": "multipart/form-data",
      Authorization: `Bearer ${idToken.value}`,
    },
  };
};

/**
 * AxiosErrorかチェックする。
 * 参考
 * catchしたerrorがAxiosErrorか判定する
 * https://zenn.dev/wintyo/articles/b417e310efc67e
 * @param error エラー
 * @returns チェック結果(true:AxiosError/false:AxisoError以外)
 */
const isAxiosError = (error: any): error is AxiosError => {
  return !!error.isAxiosError;
};

/**
 * HTTPステータスを取得する。
 * @param error エラー
 * @returns HTTPステータス(取得できない場合0)
 */
const getStatus = (error: any): number => {
  if (isAxiosError(error)) {
    const response = error.response;
    if (response != null) {
      const { status } = response;
      return status;
    }
  }
  return 0;
};

/**
 * 未認証かチェックする。
 * @param error エラー
 * @returns チェック結果(true:未認証/false:認証済み)
 */
const isUnauthorized = (error: any): boolean => {
  const status = getStatus(error);
  return status == HttpStatus.Unauthorized;
};

/**
 * エラーメッセージを取得する。
 * @param error エラー
 * @returns エラーメッセージ文字列(エラーコード,エラーメッセージ)
 */
const getErrorMessage = (error: any): string => {
  let errorMessage = "";
  if (error instanceof AxiosError) {
    const response = error.response;
    if (response != null) {
      const { status, statusText, data } = response;
      errorMessage = `${status},${statusText},${data.detail}`;
    } else {
      const { code, message } = error;
      errorMessage = `${code},${message},`;
    }
  }
  return errorMessage;
};

/**
 * IDトークンを取得する。
 * 参考
 * 【Cognito】getSessionでトークン情報を再取得する方法
 * https://medium-company.com/cognito-getsession%E3%81%A7%E8%AA%8D%E8%A8%BC%E6%83%85%E5%A0%B1%E3%82%92%E5%86%8D%E5%8F%96%E5%BE%97%E3%81%99%E3%82%8B%E6%96%B9%E6%B3%95/
 */
const getIdToken = async (): Promise<boolean> => {
  const poolData = {
    UserPoolId: process.env.VUE_APP_USER_POOL_ID,
    ClientId: process.env.VUE_APP_CLIENT_ID,
  };
  const userData = {
    Username: userId.value,
    Pool: new CognitoUserPool(poolData),
  };
  const cognitoRefreshToken = new CognitoRefreshToken({
    RefreshToken: refreshToken.value,
  });
  const cognitoUser = new CognitoUser(userData);
  try {
    const result = await new Promise((resolve, reject) => {
      cognitoUser.refreshSession(cognitoRefreshToken, (error, session) => {
        if (error) {
          reject(error);
        } else {
          resolve(session);
        }
      });
    });

    // IDトークンを取得する。
    const idToken = (result as CognitoUserSession).getIdToken().getJwtToken();

    // 取得したIDトークンをストアに保存する。
    store.commit(updateIdToken, idToken);
    return true;
  } catch (error) {
    return false;
  }
};

export default {
  getAsync,
  getBlobAsync,
  postAsync,
  postFileAsync,
  putAsync,
  deleteAsync,
  createAbortController,
};
export {
  getAsync,
  getBlobAsync,
  postAsync,
  postFileAsync,
  putAsync,
  deleteAsync,
  createAbortController,
};
