import { isAdmin } from "@/export/RoleUtils";

const TabIndex = {
  Title: 0,
  Home: 1,
  EventSetting: 2,
  MasterSetting: 3,
  TagReaderSetting: 4,
  PrintSetting: 5,
  CategoryItemMaster: 6,
  RunnerMaster: 7,
  CheckpointMaster: 8,
  Logger: 9,
  Input: 10,
  Output: 11,
} as const;
type TabIndex = (typeof TabIndex)[keyof typeof TabIndex];

/**
 * 管理者ユーザと大会ユーザで適切なタグインデックスを取得する。
 * 管理者ユーザのみホームタブが表示されるためタブインデックスが異なると思ったが
 * 変わらなかった。
 * ただ実装を変更した場合に備えてこのメソッドは残しておく。
 * @param tabIndex 元のタブインデックス
 * @returns 適切なタブインデックス
 */
const getTabIndex = (tabIndex: number) => {
  if (tabIndex == TabIndex.Title) {
    return tabIndex;
  } else {
    // 今のとこ管理者ユーザと大会ユーザで同じタブインデックスを返却する。
    if (isAdmin()) {
      return tabIndex;
    } else {
      // ホームタブが表示されない分-1する。
      // →今のところこの処理は不要
      //return tabIndex - 1;
      return tabIndex;
    }
  }
};

export default { TabIndex, getTabIndex };
export { TabIndex, getTabIndex };
