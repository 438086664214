import { Framework } from "vuetify";
import CommonConst from "@/export/CommonConst";
import VuetifyUtils from "@/export/VuetifyUtils";
import { CssProperty, CssValue, CssUnit } from "@/export/CssConst";

/**
 * 画面サイズが変更された時の処理を行う。
 *
 * @param vue Vueコンポーネント
 * @returns タイトルのスタイル
 */
function onResize(vuetify: Framework): string {
  let whiteSpace = CommonConst.StringEmpty;
  let fontSize = CommonConst.StringEmpty;
  if (VuetifyUtils.isMobileSize(vuetify)) {
    // モバイル機器の場合
    whiteSpace = CssValue.PreWrap;
    fontSize = `10${CssUnit.PX}`;
  } else {
    // PCの場合
    whiteSpace = CssValue.Pre;
    fontSize = `16${CssUnit.PX}`;
  }
  const titleStyle = `${CssProperty.WhiteSpace}: ${whiteSpace} ${CssProperty.FontSize}: ${fontSize}`;
  return titleStyle;
}

export default {
  onResize,
};
export { onResize };
