import { computed } from "vue";
import { Admin } from "./RestApiConst";
import store from "@/store/index";

/**
 * ロール
 */
const role = computed(() => {
  return store.state.role;
});

/**
 * 管理者かどうかチェックする。
 * @returns チェック結果(true:管理者/false:大会ユーザ)
 */
const isAdmin = () => {
  return role.value == Admin;
};

export default { isAdmin };
export { isAdmin };
