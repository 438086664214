//const BaseUrl = "http://localhost:8000";
const BaseUrl = process.env.VUE_APP_GATEWAY_BASE_URL;

// events(大会)
const Events = `${BaseUrl}/events`;

const Event = `${BaseUrl}/event`;

const EventEid = (eid: number) => {
  return `${Event}/${eid}`;
};

const EventEidCertificateTemplate = (eid: number) => {
  return `${EventEid(eid)}/certificate_template`;
};

// sams(収集系システム)
const EventEidSam = (eid: number) => {
  return `${EventEid(eid)}/sam`;
};

// gateways(タグリーダ)
const EventEidGateways = (eid: number) => {
  return `${EventEid(eid)}/gateways`;
};

const EventEidGatewayGid = (eid: number, gid: number) => {
  return `${EventEid(eid)}/gateway/${gid}`;
};

const EventEidGatewayGidConnection = (eid: number, gid: number) => {
  return `${EventEid(eid)}/gateway/${gid}/connection`;
};

const EventEidGatewayGidCheckpoint = (eid: number, gid: number) => {
  return `${EventEid(eid)}/gateway/${gid}/checkpoint`;
};

const EventEidGatewayGidAfter = (eid: number, gid: number) => {
  return `${EventEidGatewayGid(eid, gid)}/after`;
};

// 内部定義
const EventEidGatewayGidCommand = (eid: number, gid: number) => {
  return `${EventEidGatewayGid(eid, gid)}/command`;
};

const EventEidGatewayGidCommandGetTime = (eid: number, gid: number) => {
  return `${EventEidGatewayGidCommand(eid, gid)}/get_time`;
};

const EventEidGatewayGidCommandSetTime = (eid: number, gid: number) => {
  return `${EventEidGatewayGidCommand(eid, gid)}/set_time`;
};

const EventEidGatewayGidCommandDelDbdata = (eid: number, gid: number) => {
  return `${EventEidGatewayGidCommand(eid, gid)}/del_dbdata`;
};

const EventEidGatewayGidCommandDelSddata = (eid: number, gid: number) => {
  return `${EventEidGatewayGidCommand(eid, gid)}/del_sddata`;
};

const EventEidGatewayGidCommandGetPosition = (eid: number, gid: number) => {
  return `${EventEidGatewayGidCommand(eid, gid)}/get_position`;
};

const EventEidGatewayGidCommandStartReading = (eid: number, gid: number) => {
  return `${EventEidGatewayGidCommand(eid, gid)}/start_reading`;
};

const EventEidGatewayGidCommandStopReading = (eid: number, gid: number) => {
  return `${EventEidGatewayGidCommand(eid, gid)}/stop_reading`;
};

const EventEidGatewayGidCommandGetReadingStatus = (
  eid: number,
  gid: number
) => {
  return `${EventEidGatewayGidCommand(eid, gid)}/get_reading_status`;
};

// taglogs(タグログ)
const EventEidGatewayGidTaglogs = (eid: number, gid: number) => {
  return `${EventEidGatewayGid(eid, gid)}/taglogs`;
};

const EventEidGatewayGidTaglogsRecent = (eid: number, gid: number) => {
  return `${EventEidGatewayGidTaglogs(eid, gid)}/recent`;
};

const EventEidGatewayGidTaglogsCount = (eid: number, gid: number) => {
  return `${EventEidGatewayGidTaglogs(eid, gid)}/count`;
};

// results(結果)
// 内部定義
const EventEidCheckpointCpCode = (eid: number, cpcode: number) => {
  return `${EventEid(eid)}/checkpoint/${cpcode}`;
};

const EventEidCheckpointCpcodeResults = (eid: number, cpcode: number) => {
  return `${EventEidCheckpointCpCode(eid, cpcode)}/results`;
};

const EventEidCheckpointCpcodeResultsCount = (eid: number, cpcode: number) => {
  return `${EventEidCheckpointCpcodeResults(eid, cpcode)}/count`;
};

const EventEidCheckpointCpcodeCategoryCatcodeResults = (
  eid: number,
  cpcode: number,
  catcode: number
) => {
  return `${EventEidCheckpointCpcode(eid, cpcode)}/category/${catcode}/results`;
};

const EventEidCheckpointCpcodeCategoryCatcodeResultsCount = (
  eid: number,
  cpcode: number,
  catcode: number
) => {
  return `${EventEidCheckpointCpcodeCategoryCatcodeResults(
    eid,
    cpcode,
    catcode
  )}/count`;
};

const EventEidCategoryCatcodeCsvResult = (eid: number, catcode: number) => {
  return `${EventEid(eid)}/category/${catcode}/csv_result`;
};

const EventEidCategoryCatcodePdfResult = (eid: number, catcode: number) => {
  return `${EventEid(eid)}/category/${catcode}/pdf_result`;
};

const EventEidResult = (eid: number) => {
  return `${EventEid(eid)}/result`;
};

const EventEidNumberNumberResults = (eid: number, number: string) => {
  return `${EventEid(eid)}/number/${number}/results`;
};

const EventEidResults = (eid: number) => {
  return `${EventEid(eid)}/results`;
};

const EventEidResultCounts = (eid: number) => {
  return `${EventEid(eid)}/result/counts`;
};

const EventEidResultCsvMeasured = (eid: number) => {
  return `${EventEid(eid)}/result/csv_measured`;
};

const EventEidResultPdfMeasured = (eid: number) => {
  return `${EventEid(eid)}/result/pdf_measured`;
};

// masters(マスタファイル)
const EventEidMaster = (eid: number): string => {
  return `${EventEid(eid)}/master`;
};

// runners(選手)
const EventEidRunners = (eid: number): string => {
  return `${EventEid(eid)}/runners`;
};

const EventEidRunnerRid = (eid: number, rid: number): string => {
  return `${EventEid(eid)}/runner/${rid}`;
};

const EventEidRunnersCount = (eid: number): string => {
  return `${EventEidRunners(eid)}/count`;
};

// bibs(ナンバーカード)
const EventEidBibs = (eid: number): string => {
  return `${EventEid(eid)}/bibs`;
};

// categories(種目/総合種目)
const EventEidCategories = (eid: number): string => {
  return `${EventEid(eid)}/categories`;
};

const EventEidCategoryItems = (eid: number): string => {
  return `${EventEid(eid)}/category/items`;
};

const EventEidCategoryItemCatcode = (eid: number, catcode: number): string => {
  return `${EventEid(eid)}/category/item/${catcode}`;
};

// checkpoints(計測地点)
const EventEidCheckpoints = (eid: number): string => {
  return `${EventEid(eid)}/checkpoints`;
};

const EventEidCheckpoint = (eid: number): string => {
  return `${EventEid(eid)}/checkpoint`;
};

const EventEidCheckpointCpcode = (eid: number, cpcode: number): string => {
  return `${EventEidCheckpoint(eid)}/${cpcode}`;
};

// checkpoint_types(計測地点種別)
const EventEidCheckpointTypes = (eid: number): string => {
  return `${EventEid(eid)}/checkpoint_types`;
};

const EventEidCheckpointTypeCptid = (eid: number, cptid: number): string => {
  return `${EventEid(eid)}/checkpoint_type/${cptid}`;
};

// cognito
const CustomRole = "custom:role";
const Admin = "admin";

const FileType = {
  TextCsv: "text/csv",
};
type FileType = (typeof FileType)[keyof typeof FileType];

const MasterFileKey = {
  CategoryItem: "category_item",
  Runner: "runner",
  Category: "category",
  Gateway: "checkpoint",
};
type MasterFileKey = (typeof MasterFileKey)[keyof typeof MasterFileKey];

const CertificateTemplateFileKey = "certificate_template";

export default {
  BaseUrl,

  // events(大会)
  Events,
  Event,
  EventEid,
  EventEidCertificateTemplate,

  // sams(収集系システム)
  EventEidSam,

  // gateways(タグリーダ)
  EventEidGateways,
  EventEidGatewayGid,
  EventEidGatewayGidConnection,
  EventEidGatewayGidCheckpoint,
  EventEidGatewayGidAfter,
  EventEidGatewayGidCommandGetTime,
  EventEidGatewayGidCommandSetTime,
  EventEidGatewayGidCommandDelDbdata,
  EventEidGatewayGidCommandDelSddata,
  EventEidGatewayGidCommandGetPosition,
  EventEidGatewayGidCommandStartReading,
  EventEidGatewayGidCommandStopReading,
  EventEidGatewayGidCommandGetReadingStatus,

  // taglogs(タグログ)
  EventEidGatewayGidTaglogs,
  EventEidGatewayGidTaglogsRecent,
  EventEidGatewayGidTaglogsCount,

  // results(結果)
  EventEidCheckpointCpcodeResults,
  EventEidCheckpointCpcodeResultsCount,
  EventEidCheckpointCpcodeCategoryCatcodeResults,
  EventEidCheckpointCpcodeCategoryCatcodeResultsCount,
  EventEidCategoryCatcodeCsvResult,
  EventEidCategoryCatcodePdfResult,
  EventEidResult,
  EventEidNumberNumberResults,
  EventEidResults,
  EventEidResultCounts,
  EventEidResultCsvMeasured,
  EventEidResultPdfMeasured,

  // masters(マスタファイル)
  EventEidMaster,

  // runners(選手)
  EventEidRunners,
  EventEidRunnerRid,
  EventEidRunnersCount,

  // bibs(ナンバーカード)
  EventEidBibs,

  // categories(種目/総合種目)
  EventEidCategories,
  EventEidCategoryItems,
  EventEidCategoryItemCatcode,

  // checkpoints(計測地点)
  EventEidCheckpoints,
  EventEidCheckpointCpcode,
  EventEidCheckpoint,

  // checkpoint_types(計測地点種別)
  EventEidCheckpointTypes,
  EventEidCheckpointTypeCptid,

  // cognito
  CustomRole,
  Admin,

  FileType,
  MasterFileKey,

  CertificateTemplateFileKey,
};
export {
  BaseUrl,

  // events(大会)
  Events,
  Event,
  EventEid,
  EventEidCertificateTemplate,

  // sams(収集系システム)
  EventEidSam,

  // gateways(タグリーダ)
  EventEidGateways,
  EventEidGatewayGid,
  EventEidGatewayGidConnection,
  EventEidGatewayGidCheckpoint,
  EventEidGatewayGidAfter,
  EventEidGatewayGidCommandGetTime,
  EventEidGatewayGidCommandSetTime,
  EventEidGatewayGidCommandDelDbdata,
  EventEidGatewayGidCommandDelSddata,
  EventEidGatewayGidCommandGetPosition,
  EventEidGatewayGidCommandStartReading,
  EventEidGatewayGidCommandStopReading,
  EventEidGatewayGidCommandGetReadingStatus,

  // taglogs(タグログ)
  EventEidGatewayGidTaglogs,
  EventEidGatewayGidTaglogsRecent,
  EventEidGatewayGidTaglogsCount,

  // results(結果)
  EventEidCheckpointCpcodeResults,
  EventEidCheckpointCpcodeResultsCount,
  EventEidCheckpointCpcodeCategoryCatcodeResults,
  EventEidCheckpointCpcodeCategoryCatcodeResultsCount,
  EventEidCategoryCatcodeCsvResult,
  EventEidCategoryCatcodePdfResult,
  EventEidResult,
  EventEidNumberNumberResults,
  EventEidResults,
  EventEidResultCounts,
  EventEidResultCsvMeasured,
  EventEidResultPdfMeasured,

  // masters(マスタファイル)
  EventEidMaster,

  // runners(選手)
  EventEidRunners,
  EventEidRunnerRid,
  EventEidRunnersCount,

  // bibs(ナンバーカード)
  EventEidBibs,

  // categories(種目/総合種目)
  EventEidCategories,
  EventEidCategoryItems,
  EventEidCategoryItemCatcode,

  // checkpoints(計測地点)
  EventEidCheckpoints,
  EventEidCheckpointCpcode,
  EventEidCheckpoint,

  // cognito
  CustomRole,
  Admin,
  FileType,
  MasterFileKey,
  CertificateTemplateFileKey,
};
