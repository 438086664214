type ReplaceEventItemType = {
  eventName: string;
  eventDate: string;
  isPublic: boolean;
};
const InitialReplaceEventItem = {
  eventName: "",
  eventDate: "",
  isPublic: false,
};

export default { InitialReplaceEventItem };
export { ReplaceEventItemType, InitialReplaceEventItem };
