import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const updateIdToken = "updateIdToken";
const updateRefreshToken = "updateRefreshToken";
const updateRole = "updateRole";
const updateUserId = "updateUserId";
const updatePassword = "updatePassword";
const updateEventId = "updateEventId";
const updateEventName = "updateEventName";
const updateEpReceiver = "updateEpReceiver";
const updateTaglogCash = "updateTaglogCash";

export default new Vuex.Store({
  state: {
    idToken: "",
    refreshToken: "",
    role: "",
    userId: "",
    password: "",
    eventId: 0,
    eventName: "",
    epReceiver: "",
    taglogCash: "",
  },
  getters: {
    getIdToken: (state) => {
      return state.idToken;
    },
    getRefreshToken: (state) => {
      return state.refreshToken;
    },
    getRole: (state) => {
      return state.role;
    },
    getUserId: (state) => {
      return state.userId;
    },
    getPassword: (state) => {
      return state.password;
    },
    getEventId: (state) => {
      return state.eventId;
    },
    getEventName: (state) => {
      return state.eventName;
    },
    getEpReciever: (state) => {
      return state.epReceiver;
    },
    getTaglogCash: (state) => {
      return state.taglogCash;
    },
  },
  mutations: {
    updateIdToken: (state, value) => {
      state.idToken = value;
    },
    updateRefreshToken: (state, value) => {
      state.refreshToken = value;
    },
    updateRole: (state, value) => {
      state.role = value;
    },
    updateUserId: (state, value) => {
      state.userId = value;
    },
    updatePassword: (state, value) => {
      state.password = value;
    },
    updateEventId: (state, value) => {
      state.eventId = value;
    },
    updateEventName: (state, value) => {
      state.eventName = value;
    },
    updateEpReceiver: (state, value) => {
      state.epReceiver = value;
    },
    updateTaglogCash: (state, value) => {
      state.taglogCash = value;
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      // ストレージのキーを指定。デフォルトではvuex
      key: "vuex",
      // 管理対象のステートを指定
      paths: [
        "idToken",
        "refreshToken",
        "userId",
        "password",
        "role",
        "eventId",
        "eventName",
        "upReceiver",
        "taglogCash",
      ],
      // ストレージの種類を指定。デフォルトではローカルストレージ
      storage: window.sessionStorage,
    }),
  ],
});

export {
  updateIdToken,
  updateRefreshToken,
  updateRole,
  updateUserId,
  updatePassword,
  updateEventId,
  updateEventName,
  updateEpReceiver,
  updateTaglogCash,
};
