import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import TopView from "@/views/TopView.vue";
import LoginView from "@/views/login/LoginView.vue";
import EventListView from "@/views/EventListView.vue";

import EventListBody from "@/components/event_list/EventListBody.vue";
import HomeBody from "@/components/home/HomeBody.vue";

// 設定
import EventSettingBody from "@/components/event_setting/EventSettingBody.vue";
import MasterSettingBody from "@/components/master_setting/MasterSettingBody.vue";
import TagReaderSettingBody from "@/components/tag_reader_setting/TagReaderSettingBody.vue";
import PrintSettingBody from "@/components/print_setting/PrintSettingBody.vue";

// 改廃
import CategoryItemMasterBody from "@/components/category_item_master/CategoryItemMasterBody.vue";
import RunnerMasterBody from "@/components/runner_master/RunnerMasterBody.vue";
import CheckpointeMaster from "@/components/checkpoint_master/CheckpointMasterBody.vue";

// オペレーション
import LoggerBody from "@/components/logger/LoggerBody.vue";
import InputBody from "@/components/input/InputBody.vue";

// 出力
import OutputBody from "@/components/output/OutputBody.vue";
import OutputCsvFileBody from "@/components/output_csv_file/OutputCsvFileBody.vue";
import PrintResultBody from "@/components/print_result/PrintResultBody.vue";
import PrintMeasuredBody from "@/components/print_measured/PrintMeasuredBody.vue";

import { Path } from "@/export/PathConst";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: Path.Top,
    component: TopView,
  },
  {
    // ログイン
    path: "/login",
    name: Path.Login,
    component: LoginView,
    props: { default: true },
  },
  {
    // 大会一覧
    path: "/eventList",
    name: Path.EventList,
    component: EventListView,
    props: { default: true },
    children: [
      // 大会一覧
      {
        path: "/eventList",
        // nameは"Duplicate named routes definition"エラーが表示されるため設定しない。
        name: Path.EventList,
        components: {
          default: EventListBody,
        },
        props: { default: true },
      },

      // ホーム
      {
        path: "/home",
        name: Path.Home,
        components: {
          default: HomeBody,
        },
        props: { default: true },
      },

      // 設定
      // 大会設定
      {
        path: "/eventSetting",
        name: Path.EventSetting,
        components: {
          default: EventSettingBody,
        },
        props: { default: true },
      },

      // マスター設定
      {
        path: "/masterSetting",
        name: Path.MasterSetting,
        components: {
          default: MasterSettingBody,
        },
        props: { default: true },
      },

      // タグリーダー設定
      {
        path: "/tagReaderSetting",
        name: Path.TagReaderSetting,
        components: {
          default: TagReaderSettingBody,
        },
        props: { default: true },
      },

      // 印刷設定
      {
        path: "/PrintSetting",
        name: Path.PrintSetting,
        components: {
          default: PrintSettingBody,
        },
        props: { default: true },
      },

      // 改廃
      // 種目マスタ
      {
        path: "/categoryItemMaster",
        name: Path.CategoryItemMaster,
        components: {
          default: CategoryItemMasterBody,
        },
        props: { default: true },
      },

      // 選手マスタ
      {
        path: "/runnerMaster",
        name: Path.RunnerMaster,
        components: {
          default: RunnerMasterBody,
        },
        props: { default: true },
      },

      // 計測地点マスタ
      {
        path: "/checkpointMaster",
        name: Path.CheckpointMaster,
        components: {
          default: CheckpointeMaster,
        },
        props: { default: true },
      },

      // オペレーション
      // ロガー
      {
        path: "/logger",
        name: Path.Logger,
        components: {
          default: LoggerBody,
        },
        props: { default: true },
      },

      // インプット
      {
        path: "/input",
        name: Path.Input,
        components: {
          default: InputBody,
        },
        props: { default: true },
      },

      // 出力
      // 出力
      {
        path: "/output",
        name: Path.Output,
        components: {
          default: OutputBody,
        },
        props: { default: true },
      },

      // CSVファイル出力
      {
        path: "/outputCsvFile",
        name: Path.OutputCsvFile,
        components: {
          default: OutputCsvFileBody,
        },
        props: { default: true },
      },

      // リザルト印刷
      {
        path: "/printResult",
        name: Path.PrintResult,
        components: {
          default: PrintResultBody,
        },
        props: { default: true },
      },

      // 計測人数印刷
      {
        path: "/printMeasured",
        name: Path.PrintMeasured,
        components: {
          default: PrintMeasuredBody,
        },
        props: { default: true },
      },
    ],
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
