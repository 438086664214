import { render, staticRenderFns } from "./InputBody.vue?vue&type=template&id=33457ba9&scoped=true&"
import script from "./InputBody.vue?vue&type=script&lang=ts&setup=true&"
export * from "./InputBody.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./InputBody.vue?vue&type=style&index=0&id=33457ba9&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33457ba9",
  null
  
)

export default component.exports