import VueRouter from "vue-router";
import Vue from "vue";
import { Dictionary } from "vue-router/types/router";

// 参考
// Vue2 Composition API でのVue Router3系の使い方
// https://qiita.com/gw513405/items/3fe117e5bd913440c4c1
/**
 * 画面を切り替える。
 *
 * @param router VueRouter
 * @param path 切り替える画面のパス
 * @param params? パラメータ
 */
function pushRouter(
  router: VueRouter,
  path: string,
  params?: Dictionary<string>
): void {
  if (params == null) {
    // パラメータがない場合
    params = { normal: "1" };
    router.push({ name: path, params: params }).catch((e) => console.log(e));
  } else {
    // パラメータがある場合
    params.normal = "1";
    router.push({ name: path, params: params }).catch((e) => console.log(e));
  }
}

export default {
  pushRouter,
};

export { pushRouter };
