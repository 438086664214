/**
 * ブレークポイント名
 */
const BreakPointName = {
  XS: "xs", // eXtra Small 小型から大型のスマホ
  SM: "sm", // SMall 小型から中型のタブレット
  MD: "md", // MeDium 大型タブレットからノートパソコン
  LG: "lg", // LarGe デスクトップ
  XL: "xl", // eXtra Large ウルトラワイド
} as const;
type BreakPointName = (typeof BreakPointName)[keyof typeof BreakPointName];

const FooterProps = { "items-per-page-text": "行/ページ:" };
// const FooterProps = {
//   ItemsPerPageText: { "items-per-page-text": "行/ページ:" },
// };

type SelectItemsType = {
  text: string;
  value: number;
};

/**
 * テーブルヘッダ高さ
 */
const TableHeaderHeight = 48;

/**
 * テーブルヘッダDense高さ
 */
const TableHeaderDenseHeight = 32;

/**
 * テーブル行高さ
 */
const TableRowHeight = 48;

/**
 * テーブルDense行高さ
 */
const TableRowDenseHeight = 32;

export default {
  BreakPointName,
  FooterProps,
  TableHeaderHeight,
  TableHeaderDenseHeight,
  TableRowHeight,
  TableRowDenseHeight,
};
// exportを使う方法
// https://prograshi.com/language/vue-js/how-to-use-export-default-in-vue-and-js/
export {
  BreakPointName,
  FooterProps,
  SelectItemsType,
  TableHeaderHeight,
  TableHeaderDenseHeight,
  TableRowHeight,
  TableRowDenseHeight,
};
