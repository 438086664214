// exportを使う方法
// https://prograshi.com/language/vue-js/how-to-use-export-default-in-vue-and-js/
import { BreakPointName } from "./VuetifyConst";
import { Framework } from "vuetify";

/**
 * モバイルサイズかチェックする。
 *
 * @param vue Vueコンポーネント
 * @returns モバイルサイズか否か(true:モバイルサイズ/false:モバイルサイズではない)
 */
function isMobileSize(vuetify: Framework): boolean {
  const breakPointName = vuetify.breakpoint.name;
  // ウルトラワイドサイズまたはデスクトップサイズではない場合、true
  return !(
    breakPointName == BreakPointName.XL || breakPointName == BreakPointName.LG
  );
}

export default {
  isMobileSize,
};
export { isMobileSize };
