/**
 * 参考:src/backend/user/techrun/config.py
 */
const SamStatus = {
  IDLE: "IDLE",
  CREATING: "CREATING",
  CREATE_IN_PROGRESS: "CREATE_IN_PROGRESS",
  DELETE_IN_PROGRESS: "DELETE_IN_PROGRESS",
  REVIEW_IN_PROGRESS: "REVIEW_IN_PROGRESS",
  CREATE_COMPLETE: "CREATE_COMPLETE",
  DELETE_FAILED: "DELETE_FAILED",
  DELETE_COMPLETE: "DELETE_COMPLETE",
} as const;
type SamStatus = (typeof SamStatus)[keyof typeof SamStatus];

const SamStatusText = {
  IDLE: "運用停止",
  CREATE_COMPLETE: "運用開始",

  // 運用開始/運用停止後の状態
  PROCESSING: "処理中",
} as const;
type SamStatusText = (typeof SamStatusText)[keyof typeof SamStatusText];

export default { SamStatus, SamStatusText };
export { SamStatus, SamStatusText };
