/**
 * タグログ項目タイプ
 */
type TaglogItemType = {
  no: number;
  taglogId: number;
  tagNumber: string;
  firstTimestamp: string;
  lastTimestamp: string;
  peakTimestamp: string;
  time: string; // 表示用
};

/**
 * タグリーダー項目タイプ
 */
type TagReaderItemType = {
  text: string; // タグリーダー名
  value: number; // タグリーダーID
  checkPointName: string; // 計測地点名
  connectStatus: string; /// 接続状態
};

/**
 * 接続状態名
 */
const ConnectStatus = {
  NotConnect: "未接続",
  Connect: "接続済",
} as const;
type ConnectStatus = (typeof ConnectStatus)[keyof typeof ConnectStatus];

/**
 * 接続色
 */
const ConnectColor = {
  NotConnect: "red",
  Connect: "green",
} as const;
type ConnectColor = (typeof ConnectColor)[keyof typeof ConnectColor];

export { TaglogItemType, TagReaderItemType, ConnectStatus, ConnectColor };

export default {
  ConnectStatus,
  ConnectColor,
};
