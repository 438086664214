import { StringEmpty } from "@/export/CommonConst";

/**
 * 結果数タイプ
 */
type ResultsCountType = {
  categoryCode: number; // category_code
  categoryName: string; // category_name
  total: number; // total
  qualified: number; // qualified
  disqualified: number; // disqualified
};

/**
 * 結果数デフォルト値
 */
const DefaultResultsCount = {
  categoryCode: 0,
  categoryName: StringEmpty,
  total: 0,
  qualified: 0,
  disqualified: 0,
};

/**
 * 表示用結果項目タイプ
 */
type ResultItemType = {
  rowNum: number; // row_num
  numberCard: string; // number_card
  checkpointCode: number; // checkpoint_code
  rno: number; // rno
  tagNumber: string; // tag_number
  eventDate: string; // timestamp_str(大会日時)の年月日のみ(区切り文字無し)
  eventTime: string; // timestamp_str(大会日時)の時分秒のみ(区切り文字無し)
  formatEventTime: string; // timestamp_str(大会日時)の時分秒のみ(区切り文字":"付き)
  categoryItemName: string; // category_item_name
  grossTime: string; // record_time(グロスタイム)※未使用
  formatGrossTime: string; // record_time(表示用グロスタイム)※未使用
  grossTimeMsec: string; // record_time_msec(ミリ秒付きグロスタイム)※未使用
  formatGrossTimeMsec: string; // record_time_msec(表示用ミリ秒付きグロスタイム)
  isDisqualified: boolean; // is_disqualified
  deviceType: number; // device_type
};

/**
 * 表示用結果項目デフォルト値
 */
const DefaultResultItem = {
  rowNum: 0,
  numberCard: StringEmpty,
  checkpointCode: 0,
  rno: 0,
  tagNumber: StringEmpty,
  eventTime: StringEmpty,
  eventDate: StringEmpty,
  formatEventTime: StringEmpty,
  categoryItemName: StringEmpty,
  grossTime: StringEmpty,
  formatGrossTime: StringEmpty,
  grossTimeMsec: StringEmpty,
  formatGrossTimeMsec: StringEmpty,
  isDisqualified: false,
  deviceType: 0,
};

/**
 * 表示用検索項目タイプ
 */
type SearchItemType = {
  rowNum: number; // 連番
  numberCard: string; // number_card
  checkpointCode: number; // checkpoint_code
  checkpointName: string; // checkpoint_name
  rno: number; // rno
  eventDate: string; // timestamp_str(大会日時)の年月日のみ(区切り文字無し)
  eventTime: string; // timestamp_str(大会日時)の時分秒のみ(区切り文字無し)
  formatEventTime: string; // timestamp_str(大会日時)の時分秒のみ(区切り文字":"付き)
  invalidFlag: boolean; // invalid_flag
  invalidFlagText: string; // invalid_flagにより設定する(true:"失格/削除"/false:無し)
  deviceType: number; // device_type
  categoryItemName: string; // category_item_name※未実装
};

/**
 * 表示用検索項目
 */
const DefaultSearchItem = {
  rowNum: 0,
  numberCard: StringEmpty,
  checkpointCode: 0,
  checkpointName: StringEmpty,
  rno: 0,
  eventDate: StringEmpty,
  eventTime: StringEmpty,
  formatEventTime: StringEmpty,
  invalidFlag: false,
  invalidFlagText: StringEmpty,
  deviceType: 0,
  categoryItemName: StringEmpty,
};

/**
 * 登録用結果項目タイプ
 */
type RegistResultItemType = {
  numberCard: string; // number_card
  checkpointCode: number; // checkpoint_code
  eventDate: string; // timestamp_strの年月日のみ(区切り文字無し)
  eventTime: string; // timestamp_strの時分秒のみ(区切り文字無し)
};

/**
 * 登録要結果項目デフォルト値
 */
const DefaultRegistResultItem = {
  numberCard: StringEmpty,
  checkpointCode: 0,
  eventTime: StringEmpty,
  eventDate: StringEmpty,
};

/**
 * 編集(置換/削除)用結果項目タイプ
 */
type EditResultItemType = {
  numberCard: string; // number_card
  checkpointCode: number; // checkpoint_code
  rno: number; // rno
  eventDate: string; // timestamp_strの年月日のみ(区切り文字無し)
  eventTime: string; // timestamp_strの時分秒のみ(区切り文字無し)
  //invalid_flagはプログラムで設定する(置換:false/削除:true)
};

/**
 * 編集(置換/削除)要結果項目デフォルト値
 */
const DefaultEditResultItem = {
  numberCard: StringEmpty,
  checkpointCode: 0,
  rno: 0,
  eventTime: StringEmpty,
  eventDate: StringEmpty,
};

/**
 * 全種目コード
 */
const AllCategoryItemCode = 0;

export default {
  DefaultResultsCount,
  DefaultResultItem,
  DefaultSearchItem,
  DefaultRegistResultItem,
  DefaultEditResultItem,
  AllCategoryItemCode,
};

export {
  ResultsCountType,
  DefaultResultsCount,
  ResultItemType,
  DefaultResultItem,
  SearchItemType,
  DefaultSearchItem,
  RegistResultItemType,
  DefaultRegistResultItem,
  EditResultItemType,
  DefaultEditResultItem,
  AllCategoryItemCode,
};
