/**
 * CSSプロパティ
 */
const CssProperty = {
  FontSize: "font-size",
  FontWieght: "font-weight",
  WhiteSpace: "white-space",
} as const;
type CssProperty = (typeof CssProperty)[keyof typeof CssProperty];

/**
 * CSS値
 */
const CssValue = {
  Pre: "pre;",
  PreWrap: "pre-wrap;",
} as const;
type CssValue = (typeof CssValue)[keyof typeof CssValue];

/**
 * CSS単位
 * 参考
 * CSS の値と単位
 * https://developer.mozilla.org/ja/docs/Learn/CSS/Building_blocks/Values_and_units
 */
const CssUnit = {
  // 絶対値の長さの単位
  CM: "cm;",
  MM: "mm;",
  Q: "Q;",
  IN: "in;",
  PC: "pc;",
  PT: "pt;",
  PX: "px;",

  // 相対値の長さの単位
  EM: "em;",
  EX: "ex;",
  CH: "ch;",
  REM: "rem;",
  LH: "lh;",
  VW: "vw;",
  VH: "vh;",
  VMIN: "vmin;",
  VMAX: "vmax;",
} as const;
type CssUnit = (typeof CssUnit)[keyof typeof CssUnit];

export default {
  CssProperty,
  CssValue,
  CssUnit,
};
export { CssProperty, CssValue, CssUnit };
