import { StringEmpty } from "./CommonConst";
import Encoding from "encoding-japanese";

/**
 * 非同期でファイルを読み込む。
 * @param file ファイル
 * @returns ファイルデータ
 */
const readFileAsync = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
};

/**
 * 非同期でテキストファイルを読み込む。
 * @param file ファイル
 * @returns テキストデータ
 */
const readTextFileAsync = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });
};

const DefaultFile = new File([], StringEmpty);

/**
 * CSVファイルをダウンロードする。
 * 参考
 * JSでファイルをDL。ついでにSJISに変換（encoding.js）
 * https://tips.recatnap.info/laboratory/detail/js_convert_charcode_before_download_file
 * @param fileName ファイル名
 * @param data ファイルデータ
 */
const downloadCsvFile = (fileName: string, data: string) => {
  const codes = Encoding.stringToCode(data);
  const sjisCodeList = Encoding.convert(codes, {
    to: "SJIS",
    from: "UNICODE",
  });
  const uint8List = new Uint8Array(sjisCodeList);
  const blob = new Blob([uint8List], {
    type: "text/csv",
  });
  const urlObject = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = urlObject;
  link.download = `${fileName}.csv`;
  link.click();
};

const downloadPdfFile = (fileName: string, data: any) => {
  const blob = new Blob([data], {
    type: "application/pdf",
  });
  const urlObject = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = urlObject;
  link.download = `${fileName}.pdf`;
  link.click();
};

export default {
  readFileAsync,
  readTextFileAsync,
  DefaultFile,
  downloadCsvFile,
  downloadPdfFile,
};
export {
  readFileAsync,
  readTextFileAsync,
  DefaultFile,
  downloadCsvFile,
  downloadPdfFile,
};
