/**
 * 種目マスター項目タイプ
 */
type CategoryItemMasterItemType = {
  categoryItemCode: number;
  categoryItemName: string;
  courseType: number;
  courseTypeText: string;
  startTime: string | null;
  disqualifiedTime: string;
  measureInvalidTime: string;
  startMethod: 0;
  startMethodText: string;
  laps: number;
  isNettime: boolean;
  isNettimeText: string;
  startCheckpointCode: number | null;
  startCheckpointName: string | null;
  goalCheckpointCode: number;
  goalCheckpointName: string;
};

/**
 * コース種類の値
 */
const CourseTypeValue = {
  OneWay: 0,
  GoAround: 1,
};
type CourseTypeValue = (typeof CourseTypeValue)[keyof typeof CourseTypeValue];

/**
 * コース種類のテキスト
 */
const CourseTypeText = {
  OneWay: "ワンウェイ",
  GoAround: "周回",
};
type CourseTypeText = (typeof CourseTypeText)[keyof typeof CourseTypeText];

/**
 * コース種類の項目
 */
const CourseTypeItem = {
  OneWay: { text: CourseTypeText.OneWay, value: CourseTypeValue.OneWay },
  GoAround: { text: CourseTypeText.GoAround, value: CourseTypeValue.GoAround },
};
type CourseTypeItem = (typeof CourseTypeItem)[keyof typeof CourseTypeItem];

/**
 * スタート方式の値
 */
const StartMethodValue = {
  Normal: 0,
  Free: 1,
};
type StartMethodValue =
  (typeof StartMethodValue)[keyof typeof StartMethodValue];

/**
 * スタート方式のテキスト
 */
const StartMethodText = {
  Normal: "通常スタート",
  Free: "フリースタート",
};
type StartMethodText = (typeof StartMethodText)[keyof typeof StartMethodText];

/**
 * スタート方式の項目
 */
const StartMethodItem = {
  Normal: { text: StartMethodText.Normal, value: StartMethodValue.Normal },
  Free: { text: StartMethodText.Free, value: StartMethodValue.Free },
} as const;
type StartMethodItem = (typeof StartMethodItem)[keyof typeof StartMethodItem];

/**
 * ネットタイムフラグの値
 */
const IsNettimeValue = {
  Invalid: 0,
  Valid: 1,
};
type IsNettimeValue = (typeof IsNettimeValue)[keyof typeof IsNettimeValue];

/**
 * ネットタイムフラグのテキスト
 */
const IsNettimeText = {
  Invalid: "無効",
  Valid: "有効",
};
type IsNettimeText = (typeof IsNettimeText)[keyof typeof IsNettimeText];

/**
 * ネットタイムフラグの項目
 */
const IsNettimeItem = {
  Invalid: { text: IsNettimeText.Invalid, value: IsNettimeValue.Invalid },
  Valid: { text: IsNettimeText.Valid, value: IsNettimeValue.Valid },
} as const;
type IsNettimeItem = (typeof IsNettimeItem)[keyof typeof IsNettimeItem];

export default {
  CourseTypeValue,
  CourseTypeText,
  CourseTypeItem,
  StartMethodValue,
  StartMethodText,
  StartMethodItem,
  IsNettimeValue,
  IsNettimeText,
  IsNettimeItem,
};
export {
  CategoryItemMasterItemType,
  CourseTypeValue,
  CourseTypeText,
  CourseTypeItem,
  StartMethodValue,
  StartMethodText,
  StartMethodItem,
  IsNettimeValue,
  IsNettimeText,
  IsNettimeItem,
};
