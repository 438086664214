const Path = {
  Top: "Top",
  Login: "Login",
  EventList: "EventList",
  Home: "Home",

  // 設定
  EventSetting: "EventSetting",
  MasterSetting: "MasterSetting",
  TagReaderSetting: "TagReaderSetting",
  PrintSetting: "PrintSetting",

  // 改廃
  CategoryItemMaster: "CategoryItemMaster",
  RunnerMaster: "RunnerMaster",
  CheckpointMaster: "CheckpointMaster",

  // オペレーション
  Logger: "Logger",
  Input: "Input",

  // 出力
  Output: "Output",
  OutputCsvFile: "OutputCsvFile",
  PrintResult: "PrintResult",
  PrintMeasured: "PrintMeasured",
} as const;
type Path = (typeof Path)[keyof typeof Path];

export default {
  Path,
};
export { Path };
