import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import VueAxios from "vue-axios";
import * as sanitizeHTML from "sanitize-html";

Vue.config.productionTip = false;

Vue.config.errorHandler = (err, vm, info) => {
  console.log(`err=${err} vm=${vm} info=${info}`);
};

Vue.use(VueAxios, axios);

Vue.prototype.$sanitize = sanitizeHTML;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  data: {
    idToken: "",
    role: "",
    eventId: 0,
    eventName: "",
  },
}).$mount("#app");
