import { render, staticRenderFns } from "./CommonFooter.vue?vue&type=template&id=f381a816&scoped=true&"
import script from "./CommonFooter.vue?vue&type=script&lang=ts&setup=true&"
export * from "./CommonFooter.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./CommonFooter.vue?vue&type=style&index=0&id=f381a816&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f381a816",
  null
  
)

export default component.exports